@font-face {
  font-family: "Neue Haas Unica";
  src: url("./fonts/NeueHaasUnica-Regular.ttf");
  src: local("Neue Haas Unica Regular"), local("Neue-Haas-Unica-Regular"),
    url("./fonts/NeueHaasUnica-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

.App {
  height: 100vh;
  overflow: hidden;
}

.campmodal {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  transition: opacity 0.4s cubic-bezier(0.42, 0.01, 0, 1.04),
    visibility 0.4s cubic-bezier(0.42, 0.01, 0, 1.04),
    transform 0.4s cubic-bezier(0.42, 0.01, 0, 1.04);
  transform: scale(0);
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  /* z-index: -9999; */
}

.campmodal.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
  background-color: black;
  transition: opacity 1.4s cubic-bezier(0.42, 0.01, 0, 1.04),
    visibility 0.4s cubic-bezier(0.42, 0.01, 0, 1.04),
    transform 0.4s cubic-bezier(0.42, 0.01, 0, 1.04);
  z-index: 9999;
}

.page {
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
}
